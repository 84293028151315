import styled from "styled-components"

import { Tooltip } from "@material-ui/core"

import { useTranslate } from "src/i18n/useTranslate"
import ImportantIcon from "src/ui/icons/important-filled.svg"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function PhoneNumber({
  nbr,
  active,
}: {
  nbr: string | undefined
  active: boolean
}): JSX.Element {
  const { t, langKeys } = useTranslate()

  if (nbr) {
    return <MText color={active ? "primary" : "tertiary"}>{nbr}</MText>
  }

  const tooltipText = t(langKeys.ca_table_missing_phone_number)

  const color = active ? "emergency" : "tertiary"

  return (
    <Tooltip title={tooltipText}>
      <Flex>
        <ImportantIcon width={18} fill={color} />
        <MText color={color}>No phone number</MText>
      </Flex>
    </Tooltip>
  )
}

export const Flex = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spacing.XS};
  align-items: center;
`
